<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper" v-if="queryParam.department !== 8">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="时间">
              <a-range-picker :defaultValue="[queryParam.begin_time, queryParam.end_time]" @change="dateChange" />
              <!-- <a-month-picker style="width: 100%" @change="onArriveDateChange" format="YYYY/MM" placeholder="请选择月份" /> -->
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="部门">
              <a-select
                showSearch
                allowClear
                :disabled="departmentDis"
                placeholder="请选择部门"
                style="width: 100%"
                v-model="queryParam.department"
              >
                <a-select-option v-for="op in GLOBAL.invoiceDepartmentMaps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票号">
              <a-input v-model="queryParam.invoice_no" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="客户名称">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator" v-if="queryParam.department !== 8">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-popover v-model="popVisible" title="选择时间" trigger="click">
        <a slot="content">
          <p><a-range-picker @change="onExportDateChange" /></p>
          <p style="text-align: right"><a-button type="primary" @click="handleExport">确定</a-button></p>
        </a>
        <a-button type="primary">导出</a-button>
      </a-popover>
    </div>
    
    <div v-if="queryParam.department !== 8">
      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
        :scroll="{ x: 'max-content' }"
        :alert="{
          show: true,
          msg: showTotal
        }"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
      </s-table>
    </div>
    <div v-else>
      <a-tabs default-active-key="1" @change="tabCallback">
        <a-tab-pane tab="商品入库明细表" key="1">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="GLOBAL.queryRowGutter">
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="入库时间">
                    <a-range-picker @change="(a, b) => onDateChange('in', a, b)"/>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="商品名称">
                    <a-input v-model="queryInParam['%good_name']"/>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-model-item label="供应商" prop="supplier_id">
                    <a-select
                      showSearch
                      allowClear
                      :filter-option="filterOption"
                      ref="supplier"
                      placeholder="选择供应商"
                      mode="multiple"
                      v-model="queryInParam['supplier_id']"
                    >
                      <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="部门">
                    <a-select
                      showSearch
                      allowClear
                      placeholder="请选择部门"
                      style="width: 100%"
                      v-model="queryParam.department"
                    >
                      <a-select-option v-for="op in GLOBAL.invoiceDepartmentMaps" :key="op.value">{{ op.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <span class="table-page-search-submitButtons">
                    <a-button type="query" @click="$refs.showInTable.refresh(true)">查询</a-button>
                    <!-- <a-button type="primary" @click="handleSaleExport('in')">导出</a-button> -->
                    <a-popover
                      v-model="popVisible6"
                      title="选择月份"
                      trigger="click">
                      <a slot="content">
                        <p><a-month-picker v-model="exportDate6" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
                        <p style="text-align: right"><a-button type="primary" @click="handleExportInExcel">确定</a-button></p>
                      </a>
                      <a-button type="primary">月度入库盘点</a-button>
                    </a-popover>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <s-table
            ref="showInTable"
            :rowKey="function(record) { return getRandomCode(8) }"
            :alert="{
              show: true,
              msg: showInTotal
            }"
            :scroll="{x: 800}"
            :columns="showInColumns"
            :data="loadShowInData">
            <span slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
          </s-table>
        </a-tab-pane>
        <a-tab-pane tab="商品出货明细表" key="2">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="GLOBAL.queryRowGutter">
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="出库时间">
                    <a-range-picker @change="(a, b) => onDateChange('out', a, b)"/>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="开票时间">
                    <a-range-picker @change="(a, b) => onDateInvoiceChange('out', a, b)"/>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="商品名称">
                    <a-input v-model="queryOutParam['%good_name']"/>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-model-item label="供应商" prop="supplier_id">
                    <a-select
                      showSearch
                      allowClear
                      :filter-option="filterOption"
                      ref="supplier"
                      placeholder="选择供应商"
                      mode="multiple"
                      v-model="queryOutParam.supplier_ids"
                    >
                      <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="购买单位">
                    <a-select
                      showSearch
                      label-in-value
                      allowClear
                      placeholder="请选择购买单位"
                      mode="multiple"
                      style="width: 100%"
                      :value="outCustomerInfo"
                      :filter-option="false"
                      :not-found-content="outFetching ? undefined : null"
                      @search="outGetDepartmentCustomer"
                      @change="outChangeCustomerInfo"
                    >
                      <template v-if="outFetching" #notFoundContent>
                        <a-spin size="small" />
                      </template>
                      <a-select-option v-for="op in outBuyerOps" :key="op.value">{{ op.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="部门">
                    <a-select
                      showSearch
                      allowClear
                      placeholder="请选择部门"
                      style="width: 100%"
                      v-model="queryParam.department"
                    >
                      <a-select-option v-for="op in GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <span class="table-page-search-submitButtons">
                    <a-button type="query" icon="search" @click="$refs.showOutTable.refresh(true)">查询</a-button>
                    <a-popover
                      v-model="popVisible7"
                      title="选择月份"
                      trigger="click">
                      <a slot="content">
                        <p><a-month-picker v-model="exportDate7" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
                        <p style="text-align: right"><a-button type="primary" @click="handleExportOutExcel">确定</a-button></p>
                      </a>
                      <a-button type="primary">月度出库盘点</a-button>
                    </a-popover>
                    <!-- <a-button type="primary" @click="handleSaleExport('out')">导出</a-button> -->
                    <!-- <a-button type="primary" @click="handleSaleInvoiceExport('out')">开票统计表</a-button> -->
                    <a-button type="primary" @click="handleInAndOutSumSheetExport">出入库总表</a-button>
                    <a-popover
                      v-model="popVisible5"
                      title="选择月份"
                      trigger="click">
                      <a slot="content">
                        <p><a-month-picker v-model="exportDate5" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
                        <p style="text-align: right"><a-button type="primary" @click="handleExportInAndOutSum">确定</a-button></p>
                      </a>
                      <a-button type="primary">账面库存盘点单（汇总表）</a-button>
                    </a-popover>
                    <a-popover
                      v-model="popVisible9"
                      title="选择月份"
                      trigger="click">
                      <a slot="content">
                        <p><a-month-picker v-model="exportDate9" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
                        <p style="text-align: right"><a-button type="primary" @click="handleExportCalendarYearInAndOutSum">确定</a-button></p>
                      </a>
                      <a-button type="primary">差异盘点单（汇总表）</a-button>
                    </a-popover>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <s-table
            ref="showOutTable"
            :rowKey="function(record) { return getRandomCode(8) }"
            :alert="{
              show: true,
              msg: showOutTotal
            }"
            :columns="showOutColumns"
            :data="loadShowOutData">
            <span slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
          </s-table>
        </a-tab-pane>
        <a-tab-pane tab="商品代销明细表" key="3">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="GLOBAL.queryRowGutter">
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="代销时间">
                    <a-range-picker @change="(a, b) => onDateChange('agent', a, b)"/>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="开票时间">
                    <a-range-picker @change="(a, b) => onDateInvoiceChange('agent', a, b)"/>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="商品名称">
                    <a-input v-model="queryAgentParam['%good_name']"/>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-model-item label="供应商" prop="supplier_id">
                    <a-select
                      showSearch
                      allowClear
                      :filter-option="filterOption"
                      ref="supplier"
                      placeholder="选择供应商"
                      mode="multiple"
                      v-model="queryAgentParam.supplier_id"
                    >
                      <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="购买单位">
                    <a-select
                      showSearch
                      label-in-value
                      allowClear
                      placeholder="请选择购买单位"
                      style="width: 100%"
                      :value="agentCustomerInfo"
                      :filter-option="false"
                      :not-found-content="agentFetching ? undefined : null"
                      @search="agentGetDepartmentCustomer"
                      @change="agentChangeCustomerInfo"
                    >
                      <template v-if="agentFetching" #notFoundContent>
                        <a-spin size="small" />
                      </template>
                      <a-select-option v-for="op in agentBuyerOps" :key="op.value">{{ op.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <a-form-item label="部门">
                    <a-select
                      showSearch
                      allowClear
                      placeholder="请选择部门"
                      style="width: 100%"
                      v-model="queryParam.department"
                    >
                      <a-select-option v-for="op in GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col v-bind="GLOBAL.queryColSpan">
                  <span class="table-page-search-submitButtons">
                    <a-button type="query" icon="search" @click="$refs.showAgentTable.refresh(true)">查询</a-button>
                    <!-- <a-button type="primary" @click="handleSaleExport('agent')">导出</a-button> -->
                    <!-- <a-button type="primary" @click="handleSaleInvoiceExport('agent')">开票统计表</a-button> -->
                    <a-popover
                      v-model="popVisible8"
                      title="选择月份"
                      trigger="click">
                      <a slot="content">
                        <p><a-month-picker v-model="exportDate8" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
                        <p style="text-align: right"><a-button type="primary" @click="handleExportAgentExcel">确定</a-button></p>
                      </a>
                      <a-button type="primary">月度代销汇总</a-button>
                    </a-popover>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <s-table
            ref="showAgentTable"
            :rowKey="function(record) { return getRandomCode(8) }"
            :alert="{
              show: true,
              msg: showAgentTotal
            }"
            :columns="showAgentColumns"
            :data="loadShowAgentData">
            <span slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
          </s-table>
        </a-tab-pane>
        <a-tab-pane tab="商品库存明细表" key="4">
          <Stock/>
        </a-tab-pane>
      </a-tabs>
    </div>

  </a-card>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import { claimFmsFinanceInfo, invoiceListInfo, exportInvoiceData, invoiceListInfoTotal } from '@/api/fms'
import { getCustomer, getSupplier, downloadExcel } from '@/api/common'
import debounce from 'lodash/debounce';
import Stock from '@/views/wms/sale/Stock'
import { getFirstLetter, getRandomCode } from '@/utils/util'
import {
  // exportStaticsDetail, 
  exportStockDetail,
  getFmsStaticsShowInDetail,
  getFmsStaticsShowOutDetail,
  getFmsStaticsShowAgentDetail,
  getStockPage,
  // exportInvoiceStaticsDetail,
  handleInAndOutSumSheetExport,
  handleExportInAndOutSum,
  handleExportCalendarYearInAndOutSum,
  handleExportIn,
  handleExportOut,
  handleExportAgent
} from '@/api/wms/sale'
export default {
  components: {
    STable,
    Stock
  },
  props: {
    pageType: {
      type: Number,
      default: null
    }
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      formVisible: false,
      confirmLoading: false,
      queryParam: {
        department: 2,
        begin_time: moment(new Date()).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(new Date()).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      },
      saleKey: '1',
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      defaultMonth: '',
      userOps: [],
      showTotal: '',
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: text => {
            return this.GLOBAL.departmentMaps[text] ? this.GLOBAL.departmentMaps[text].name : ''
          },
          width: 100
        },
        {
          title: '发票号',
          dataIndex: 'invoice_no',
          width: 120,
          customRender: text => {
            return typeof text !== 'undefined' ? text.join('\n') : ''
          }
        },
        {
          title: '开票人',
          dataIndex: 'invoice_name',
          width: 100
        },
        {
          title: '开票时间',
          dataIndex: 'invoice_date',
          width: 120
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name',
          width: 150
        },
        {
          title: '费用名称',
          dataIndex: 'fee_name',
          width: 200,
          customRender: text => {
            return typeof text !== 'undefined' ? text.join('\n') : ''
          }
        },
        {
          title: '税金',
          dataIndex: 'claim_date',
          width: 80
        },
        {
          title: '业务编号',
          dataIndex: 'business_no',
          width: 250,
          customRender: text => {
            return typeof text !== 'undefined' ? text.join('\n') : ''
          }
        },
        {
          title: '应收总计(CNY)',
          dataIndex: 'amount',
          customRender: text => {
            return typeof text !== 'undefined' ? (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,') : ''
          },
          width: 120
        },
        {
          title: '应付总计(CNY)',
          dataIndex: 'pay_amount',
          customRender: text => {
            return typeof text !== 'undefined' ? (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,') : ''
          },
          width: 120
        },
        {
          title: '利润(CNY)',
          dataIndex: 'profit',
          customRender: text => {
            return typeof text !== 'undefined' ? (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,') : ''
          },
          width: 120
        },
        {
          title: '应收总计(USD)',
          dataIndex: 'foreign_currency_amount',
          customRender: text => {
            return typeof text !== 'undefined' ? (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,') : ''
          },
          width: 120
        },
        {
          title: '应付总计(USD)',
          dataIndex: 'foreign_currency_pay_amount',
          customRender: text => {
            return typeof text !== 'undefined' ? (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,') : ''
          },
          width: 120
        },
        {
          title: '利润(USD)',
          dataIndex: 'foreign_currency_profit',
          customRender: text => {
            return typeof text !== 'undefined' ? (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,') : ''
          },
          width: 120
        }
      ],
      rules: {
        claim_date: [{ required: true, message: '请填写认领日期', trigger: 'change' }],
        invoice_num: [{ required: true, message: '请填写发票号', trigger: 'blur' }],
        claimant: [{ required: true, message: '请选择认领人', trigger: 'blur' }]
      },
      selectedRowKeys: [],
      loadData: parameter => {
        const that = this;
        invoiceListInfoTotal(Object.assign(parameter, this.queryParam)).then(data => {
          if (that.queryParam.department !== 2) {
            that.showTotal = '票数合计:' + data.total + '/应收合计(CNY):' + data.charge + '/应付合计(CNY):' + data.pay + '/利润(CNY):' + data.profit + '/应收合计(USD):' + data.foreignCurrencyAmount + '/应付合计(USD):' + data.foreignCurrencyPayAmount + '/利润(USD):' + data.foreignCurrencyProfit
          } else {
            that.showTotal = '票数合计:' + data.total + '/应收合计(去税):' + data.untaxCharge + '/应收合计(CNY):' + data.charge + '/应付合计(CNY):' + data.pay + '/利润(CNY):' + data.profit + '/应收合计(USD):' + data.foreignCurrencyAmount + '/应付合计(USD):' + data.foreignCurrencyPayAmount + '/利润(USD):' + data.foreignCurrencyProfit
          }
        })
        return invoiceListInfo(Object.assign(parameter, this.queryParam)).then(res => {
          return {
            list: res.list,
            total: res.total
          }
        })
      },
      // 新加
      queryInParam: {},
      agentBuyerOps: [],
      agentCustomerInfo: undefined,
      agentFetching: false,
      buyerOps: [],
      outBuyerOps: [],
      outCustomerInfo: undefined,
      outFetching: false,
      queryOutParam: {},
      queryAgentParam: {},
      showInTotal: '', // 商品进货明细统计信息
      showOutTotal: '', // 商品出货明细统计信息
      showAgentTotal: '', // 商品代销明细统计信息
      supplierOps: [], // 供应商
      showInColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50
        },
        {
          title: '入库单号',
          dataIndex: 'purchase_num',
          width: 200
        },
        {
          title: '入库时间',
          dataIndex: 'purchase_date',
          width: 180
        },
        {
          title: '仓库',
          dataIndex: 'warehouse',
          width: 100
        },
        {
          title: '供应商',
          dataIndex: 'supplier_name',
          width: 200
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 150
        },
        {
          title: '单位',
          dataIndex: 'good_unit',
          width: 80
        },
        {
          title: '规格',
          dataIndex: 'good_spec',
          width: 80
        },
        // {
        //   title: '采购人',
        //   dataIndex: 'purchaser_name',
        //   width: 80
        // },
        {
          title: '录入人',
          dataIndex: 'creator',
          width: 80
        },
        {
          title: '数量',
          dataIndex: 'num',
          width: 100
        },
        {
          title: '单价',
          dataIndex: 'unit_price',
          width: 120
        },
        {
          title: '总价',
          dataIndex: 'total',
          width: 120
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 200
        }
      ],
      showOutColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50
        },
        {
          title: '出货单号',
          dataIndex: 'sell_num',
          width: 150
        },
        {
          title: '出货时间',
          dataIndex: 'out_date',
          width: 180
        },
        {
          title: '购买单位',
          dataIndex: 'buyer_name',
          width: 150
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 150
        },
        {
          title: '商品名称',
          dataIndex: 'good_unit',
          width: 150
        },
        {
          title: '单位',
          dataIndex: 'good_spec',
          width: 150
        },
        {
          title: '规格',
          dataIndex: 'unit_price',
          width: 100
        },
        {
          title: '数量',
          dataIndex: 'num',
          width: 100
        },
        {
          title: '总价',
          width: 100,
          customRender: (text, record) => {
            return record.unit_price * record.num
          }
        },
        {
          title: '录入人',
          dataIndex: 'creator',
          width: 80
        },
        {
          title: '录入时间',
          dataIndex: 'creat_at',
          width: 180
        }
      ],
      showAgentColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50
        },
        {
          title: '代销单号',
          dataIndex: 'agent_num',
          width: 150
        },
        {
          title: '代销时间',
          dataIndex: 'agent_date',
          width: 180
        },
        {
          title: '购买单位',
          dataIndex: 'buyer_name',
          width: 150
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 150
        },
        {
          title: '单位',
          dataIndex: 'good_unit',
          width: 150
        },
        {
          title: '规格',
          dataIndex: 'good_spec',
          width: 150
        },
        {
          title: '代销单价',
          dataIndex: 'unit_price',
          width: 100
        },
        {
          title: '代销数量',
          dataIndex: 'num',
          width: 100
        },
        {
          title: '代销总价',
          width: 100,
          customRender: (text, record) => {
            return record.unit_price * record.num
          }
        },
        {
          title: '录入人',
          dataIndex: 'creator',
          width: 80
        },
        {
          title: '录入时间',
          dataIndex: 'creat_at',
          width: 180
        }
      ],
      loadShowInData: parameter => {
        return getFmsStaticsShowInDetail(Object.assign(parameter, this.queryInParam))
          .then(res => {
            this.showInTotal = '数量总计：' + (res.total_row && res.total_row.num || 0) + '、总价总计：' + (res.total_row && res.total_row.total_price || 0) + '、成本价总计：' + (res.total_row && res.total_row.price_in_tax || 0)
            return res
          })
      },
      loadShowOutData: parameter => {
        this.queryOutParam.type = 1
        return getFmsStaticsShowOutDetail(Object.assign(parameter, this.queryOutParam))
          .then(res => {
            this.showOutTotal = '数量总计：' + (res.total_row && res.total_row.num || 0) + '、总价总计：' + (res.total_row && res.total_row.total_price || 0) + '、成本价总计：' + (res.total_row && res.total_row.price_in_tax || 0)
            return res
          })
      },
      loadShowAgentData: parameter => {
        this.queryAgentParam.type = 1
        return getFmsStaticsShowAgentDetail(Object.assign(parameter, this.queryAgentParam))
          .then(res => {
            this.showAgentTotal = '数量总计：' + (res.total_row && res.total_row.num || 0) + '、总价总计：' + (res.total_row && res.total_row.total_price || 0) + '、成本价总计：' + (res.total_row && res.total_row.price_in_tax || 0)
            return res
          })
      },
      stockQueryParam: {},
      stockShowTotal: '',
      form: {
        purchase_id: undefined,
        purchase_num: '',
        purchase_date: null,
        supplier_id: undefined,
        warehouse_id: undefined
      },
      stockColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '入库单号',
          dataIndex: 'purchase_num'
        },
        {
          title: '入库时间',
          dataIndex: 'purchase_date'
        },
        {
          title: `供应商`,
          dataIndex: `supplier_name`
        },
        {
          title: '仓库',
          dataIndex: 'warehouse'
        },
        {
          title: '商品名称',
          dataIndex: 'good_name'
        },
        {
          title: '单位',
          dataIndex: 'good_unit'
        },
        {
          title: '规格',
          dataIndex: 'good_spec'
        },
        {
          title: '数量',
          dataIndex: 'total_num'
        },
        {
          title: '单价',
          dataIndex: 'unit_price'
        },
        {
          title: '总价',
          dataIndex: 'total'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      stockLoadData: parameter => {
        return getStockPage(Object.assign(parameter, this.stockQueryParam)).then(res => {
          this.stockShowTotal =
            '数量合计：' +
            ((res.total_row && res.total_row.num) || 0) +
            '、总价合计：' +
            ((res.total_row && res.total_row.total) || 0)
          return res
        })
      },
      departmentDis: false,
      popVisible5: false,
      exportDate5: null,
      popVisible6: false,
      exportDate6: null,
      popVisible7: false,
      exportDate7: null,
      popVisible8: false,
      exportDate8: null,
      popVisible9: false,
      exportDate9: null,
      popVisible: false
    }
  },
  created() {
    getSupplier({
      department: 8
    }).then(res => {
      this.supplierOps = res
    })
    if (this.pageType !== null) {
      this.queryParam.department = this.pageType
      this.departmentDis = true
    }
  },
  methods: {
    moment,
    // 差异盘点单（汇总表）
    handleExportCalendarYearInAndOutSum() {
      if (this.exportDate9) {
        this.queryParam['year'] = this.exportDate9.year();
        this.queryParam['month'] = this.exportDate9.month() + 1;
        handleExportCalendarYearInAndOutSum(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data;
              let fileName = ``;
              const contentDisposition = downRes.headers['content-disposition'];
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split(`=`)[1]));
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` })
              )
              const link = document.createElement(`a`);
              link.style.display = 'none';
              link.href = url;
              link.setAttribute(`download`, fileName);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
          }
        }).finally(_ => {
          this.popVisible9 = false;
        })
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    // 账面库存盘点单（汇总表）
    handleExportInAndOutSum() {
      if (this.exportDate5) {
        this.queryParam['year'] = this.exportDate5.year();
        this.queryParam['month'] = this.exportDate5.month() + 1;
        handleExportInAndOutSum(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible5 = false;
        })
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    handleExportInExcel() {
      if (this.exportDate6) {
        this.queryParam['year'] = this.exportDate6.year();
        this.queryParam['month'] = this.exportDate6.month() + 1;
        handleExportIn(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible6 = false;
        })
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    handleExportOutExcel() {
      if (this.exportDate7) {
        this.queryParam['year'] = this.exportDate7.year();
        this.queryParam['month'] = this.exportDate7.month() + 1;
        handleExportOut(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible7 = false;
        })
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    handleExportAgentExcel() {
      if (this.exportDate8) {
        this.queryParam['year'] = this.exportDate8.year();
        this.queryParam['month'] = this.exportDate8.month() + 1;
        handleExportAgent(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible8 = false;
        })
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    // 导出~出入库总表
    handleInAndOutSumSheetExport() {
      handleInAndOutSumSheetExport(this.queryOutParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data;
            let fileName = ``;
            const contentDisposition = downRes.headers['content-disposition'];
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split(`=`)[1]));
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` })
            )
            const link = document.createElement(`a`);
            link.style.display = 'none';
            link.href = url;
            link.setAttribute(`download`, fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
        }
      });
    },
    onArriveDateChange(date, dateString) {
      this.queryParam.arrive_date = dateString
    },
    dateChange(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['begin_time'] = startDate
        this.queryParam['end_time'] = endDate
      } else {
        this.queryParam['begin_time'] = null
        this.queryParam['end_time'] = null
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (typeof value !== 'undefined') {
        this.customerInfo = value
        this.queryParam.customer_name = value.key
        this.customerOps = []
        this.fetching = false
      } else {
        this.customerInfo = value
        this.queryParam.customer_name = null
        this.customerOps = []
        this.fetching = false
      }
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          claimFmsFinanceInfo({
            ...this.form,
            arrive_date: this.form.arrive_date ? moment(this.form.arrive_date).valueOf() : null,
            claim_date: this.form.claim_date ? moment(this.form.claim_date).valueOf() : null
          })
            .then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: '认领成功'
              })
              this.$refs.table.refresh(false)
            })
            .catch(_ => {
              // this.formVisible = false
              this.confirmLoading = false
            })
        }
      })
    },
    onExportDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['begin_time'] = startDate
        this.queryParam['end_time'] = endDate
      } else {
        this.queryParam['begin_time'] = null
        this.queryParam['end_time'] = null
      }
    },
    handleExport() {
      const queryParam = {}
      queryParam['invoice_no'] = this.queryParam.invoice_no
      queryParam['department'] = this.queryParam.department
      // queryParam['arrive_date'] = this.queryParam.arrive_date
      queryParam['customer_name'] = this.queryParam.customer_name
      queryParam['begin_time'] = this.queryParam.begin_time
      queryParam['end_time'] = this.queryParam.end_time
      exportInvoiceData(queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
          this.popVisible = false
        }
      })
    },
    // tab切换
    tabCallback(key) {
      this.saleKey = key
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    getRandomCode,
    onDateChange(flag, date, dateString) {
      if (dateString[0]) {
        const _date = new Date(dateString[0].concat(' 0:0:0')).getTime() + '~' + new Date(dateString[1].concat(' 23:59:59')).getTime()
        if (flag === 'in') {
          this.queryInParam['@purchase_date'] = _date
        } else if (flag === 'agent') {
          this.queryAgentParam.start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
          this.queryAgentParam.end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
          this.queryAgentParam['@agent_date'] = _date
        } else {
          this.queryOutParam.start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
          this.queryOutParam.end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
          this.queryOutParam['@out_date'] = _date
        }
      } else {
        if (flag === 'in') {
          this.queryInParam['@purchase_date'] = ''
        } else if (flag === 'agent') {
          this.queryAgentParam.start_date = null
          this.queryAgentParam.end_date = null
          this.queryAgentParam['@agent_date'] = ''
        } else {
          this.queryOutParam.start_date = null
          this.queryOutParam.end_date = null
          this.queryOutParam['@out_date'] = ''
        }
      }
    },
    onDateInvoiceChange(flag, date, dateString) {
      if (dateString[0]) {
        // const _date = new Date(dateString[0].concat(' 0:0:0')).getTime() + '~' + new Date(dateString[1].concat(' 23:59:59')).getTime()
        if (flag === 'in') {
        } else if (flag === 'agent') {
          this.queryAgentParam.invoice_start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
          this.queryAgentParam.invoice_end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        } else {
          this.queryOutParam.invoice_start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
          this.queryOutParam.invoice_end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        }
      } else {
        if (flag === 'in') {
        } else if (flag === 'agent') {
          this.queryAgentParam.invoice_start_date = null
          this.queryAgentParam.invoice_end_date = null
        } else {
          this.queryOutParam.invoice_start_date = null
          this.queryOutParam.invoice_end_date = null
        }
      }
    },
    // handleSaleExport(type) {
    //   let params = {}
    //   switch (type) {
    //     case 'in':
    //       this.queryInParam.type = 1
    //       params = { ...this.queryInParam, type: 'in' }
    //       break
    //     case 'out':
    //       this.queryOutParam.type = 1
    //       params = { ...this.queryOutParam, type: 'out' }
    //       break
    //     case 'agent':
    //       this.queryAgentParam.type = 1
    //       params = { ...this.queryAgentParam, type: 'agent' }
    //   }
    //   exportStaticsDetail(params).then(res => {
    //     if (res !== null) {
    //       const fileInfo = {
    //         fileName: res
    //       }
    //       downloadExcel(fileInfo).then(downRes => {
    //         const data = downRes.data
    //         let fileName = ''
    //         const contentDisposition = downRes.headers['content-disposition']
    //         if (contentDisposition) {
    //           fileName = decodeURI(escape(contentDisposition.split('=')[1]))
    //         }
    //         const url = window.URL.createObjectURL(
    //           new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    //         )
    //         const link = document.createElement('a')
    //         link.style.display = 'none'
    //         link.href = url
    //         link.setAttribute('download', fileName)
    //         document.body.appendChild(link)
    //         link.click()
    //         document.body.removeChild(link)
    //       })
    //     }
    //   })
    // },
    // 开票统计表
    // handleSaleInvoiceExport(type) {
    //   let params = {}
    //   switch (type) {
    //     case 'out':
    //       params = {
    //         invoiceStartDate: this.queryOutParam.invoice_start_date,
    //         invoiceEndDate: this.queryOutParam.invoice_end_date,
    //         type: 1
    //       }
    //       break
    //     case 'agent':
    //       params = {
    //         invoiceStartDate: this.queryAgentParam.invoice_start_date,
    //         invoiceEndDate: this.queryAgentParam.invoice_end_date,
    //         type: 2
    //       }
    //   }
    //   exportInvoiceStaticsDetail(params).then(res => {
    //     if (res !== null) {
    //       const fileInfo = {
    //         fileName: res
    //       }
    //       downloadExcel(fileInfo).then(downRes => {
    //         const data = downRes.data
    //         let fileName = ''
    //         const contentDisposition = downRes.headers['content-disposition']
    //         if (contentDisposition) {
    //           fileName = decodeURI(escape(contentDisposition.split('=')[1]))
    //         }
    //         const url = window.URL.createObjectURL(
    //           new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    //         )
    //         const link = document.createElement('a')
    //         link.style.display = 'none'
    //         link.href = url
    //         link.setAttribute('download', fileName)
    //         document.body.appendChild(link)
    //         link.click()
    //         document.body.removeChild(link)
    //       })
    //     }
    //   })
    // },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    agentGetDepartmentCustomer(value) {
      this.agentLastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.agentBuyerOps = [];
      this.agentFetching = true;
      getCustomer({
        department: 8,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.agentBuyerOps = res;
        this.agentFetching = false;
      })
    },
    outGetDepartmentCustomer(value) {
      this.outLastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.outBuyerOps = [];
      this.outFetching = true;
      getCustomer({
        department: 8,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.outBuyerOps = res;
        this.outFetching = false;
      })
    },
    outChangeCustomerInfo(value) {
      if (typeof value !== 'undefined') {
        this.outCustomerInfo = value
        const ids = []
        value.forEach(item => {
          ids.push(item.key)
        })
        this.queryOutParam['buyer'] = ids
        this.outBuyerOps = [];
        this.outFetching = false;
      } else {
        this.outCustomerInfo = value
        this.queryOutParam['buyer'] = []
        this.outBuyerOps = [];
        this.outFetching = false;
      }
    },
    agentChangeCustomerInfo(value) {
      if (typeof value !== 'undefined') {
        this.agentCustomerInfo = value
        this.queryAgentParam['buyer'] = value.key
        this.agentBuyerOps = [];
        this.agentFetching = false;
      } else {
        this.agentCustomerInfo = value
        this.queryAgentParam['buyer'] = ''
        this.agentBuyerOps = [];
        this.agentFetching = false;
      }
    },
    stockHandleExport(type) {
      exportStockDetail(this.stockQueryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    stockOnDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.stockQueryParam['start_date'] = startDate
        this.stockQueryParam['end_date'] = endDate
      } else {
        this.stockQueryParam['start_date'] = null
        this.stockQueryParam['end_date'] = null
      }
    }
  }
}
</script>
